export default {
	cinema: {
		address: 'г. Зеленоградск, ул. Ленина, 3',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay']
		},
		maps: null,
		selectButton: {
			options: [],
			selectedOptionValue: null
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false
	},
	feedback: true
};
